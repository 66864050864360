import React, { useState } from "react";

import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import SaveButton from "../../components/Buttons/SaveButton";
import { LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import InputAdornment from '@material-ui/core/InputAdornment';


// @material-ui components

const SellingEdit = () => {
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h2 style={{margin: 0}}>Confirme seus dados para finalizar o pedido</h2>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <TextField id="outlined-basic1" label="N. Pedido" fullWidth InputProps={{readOnly: true}} value="54352" />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <TextField id="outlined-basic1" label="Empresa" fullWidth InputProps={{readOnly: true}} value="Empresa Exemplo" />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <TextField id="outlined-basic2" label="Sigla" fullWidth InputProps={{readOnly: true}} value="Loja Exemplo" />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <TextField id="outlined-basic3" label="Serviço Solicitado" fullWidth InputProps={{readOnly: true}} value="Recolhimento de Cédulas" />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <TextField
            label="Data de Preferência"
            id="desired_date"
            value="27/02/2022"
            fullWidth
            InputProps={{readOnly: true}}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={9}>
          <TextField
            label="Observações"
            id="observations"
            value="Observações de teste"
            fullWidth
            InputProps={{readOnly: true}}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <h3 style={{margin: 0}}>Dados da Entrega:</h3>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField id="outlined-basic1" label="Endereço" fullWidth InputProps={{readOnly: true}} value="Endereço Exemplo" />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField id="outlined-basic2" label="N.:" fullWidth InputProps={{readOnly: true}} value="Valor Exemplo" />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField id="outlined-basic1" label="Complemento" fullWidth InputProps={{readOnly: true}} value="Valor Exemplo" />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField id="outlined-basic2" label="Cidade" fullWidth InputProps={{readOnly: true}} value="Valor Exemplo" />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField id="outlined-basic2" label="CEP" fullWidth InputProps={{readOnly: true}} value="Valor Exemplo" />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField id="outlined-basic2" label="UF" fullWidth InputProps={{readOnly: true}} value="Valor Exemplo" />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <h3 style={{margin: 0}}>Milheiros por moeda:</h3>
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <TextField
            variant="outlined"
            label=""
            id="coins-05"
            InputProps={{
              endAdornment: <InputAdornment position="end">R$ 0,05</InputAdornment>,
              readOnly: true
            }}
            fullWidth
            value="2"
          />
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <TextField
            variant="outlined"
            label=""
            id="coins-10"
            InputProps={{
              endAdornment: <InputAdornment position="end">R$ 0,10</InputAdornment>,
              readOnly: true
            }}
            fullWidth
            value="9"
          />
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <TextField
            variant="outlined"
            label=""
            id="coins-25"
            InputProps={{
              endAdornment: <InputAdornment position="end">R$ 0,25</InputAdornment>,
              readOnly: true
            }}
            fullWidth
            value="7"
          />
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <TextField
            variant="outlined"
            label=""
            id="coins-50"
            InputProps={{
              endAdornment: <InputAdornment position="end">R$ 0,50</InputAdornment>,
              readOnly: true
            }}
            fullWidth
            value="3"
          />
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <TextField
            variant="outlined"
            label=""
            id="coins-100"
            InputProps={{
              endAdornment: <InputAdornment position="end">R$ 1,00</InputAdornment>,
              readOnly: true
            }}
            fullWidth
            value="1"
          />
        </Grid>
        <Grid item xs={12}>
          <h3 style={{margin: 0}}>Milheiros por cédula:</h3>
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <TextField
            variant="outlined"
            label=""
            id="ballot-2"
            InputProps={{
              endAdornment: <InputAdornment position="end">R$ 2,00</InputAdornment>,
              readOnly: true
            }}
            fullWidth
            value="4"
          />
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <TextField
            variant="outlined"
            label=""
            id="ballot-5"
            InputProps={{
              endAdornment: <InputAdornment position="end">R$ 5,00</InputAdornment>,
              readOnly: true
            }}
            fullWidth
            value="2"
          />
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <TextField
            variant="outlined"
            label=""
            id="ballot-10"
            InputProps={{
              endAdornment: <InputAdornment position="end">R$ 10,00</InputAdornment>,
              readOnly: true
            }}
            fullWidth
            value="3"
          />
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <TextField
            variant="outlined"
            label=""
            id="ballot-20"
            InputProps={{
              endAdornment: <InputAdornment position="end">R$ 20,00</InputAdornment>,
              readOnly: true
            }}
            fullWidth
            value="6"
          />
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <TextField
            variant="outlined"
            label=""
            id="ballot-50"
            InputProps={{
              endAdornment: <InputAdornment position="end">R$ 50,00</InputAdornment>,
              readOnly: true
            }}
            fullWidth
            value="6"
          />
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <TextField
            variant="outlined"
            label=""
            id="ballot-100"
            InputProps={{
              endAdornment: <InputAdornment position="end">R$ 100,00</InputAdornment>,
              readOnly: true
            }}
            fullWidth
            value="6"
          />
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Box}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{background: '#ccc'}}>R$ 0,05</TableCell>
                  <TableCell style={{background: '#ccc'}}>R$ 0,10</TableCell>
                  <TableCell style={{background: '#ccc'}}>R$ 0,25</TableCell>
                  <TableCell style={{background: '#ccc'}}>R$ 0,50</TableCell>
                  <TableCell style={{background: '#ccc'}}>R$ 1,00</TableCell>
                  <TableCell style={{background: '#ccc'}} align="right"><strong>Total</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow key={1}>
                    <TableCell>R$ 150,00</TableCell>
                    <TableCell>R$ 250,00</TableCell>
                    <TableCell>R$ 750,00</TableCell>
                    <TableCell>R$ 1.500,00</TableCell>
                    <TableCell>R$ 3.500,00</TableCell>
                    <TableCell align="right"><strong>R$ 7.950,00</strong></TableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Box}>
            <Table aria-label="simple table" style={{minWidth: 650}}>
              <TableHead>
                <TableRow>
                  <TableCell style={{background: '#ccc'}}>R$ 2,00</TableCell>
                  <TableCell style={{background: '#ccc'}}>R$ 5,00</TableCell>
                  <TableCell style={{background: '#ccc'}}>R$ 10,00</TableCell>
                  <TableCell style={{background: '#ccc'}}>R$ 20,00</TableCell>
                  <TableCell style={{background: '#ccc'}}>R$ 50,00</TableCell>
                  <TableCell style={{background: '#ccc'}}>R$ 100,00</TableCell>
                  <TableCell style={{background: '#ccc'}} align="right"><strong>Total</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow key={1}>
                    <TableCell>R$ 6.000,00</TableCell>
                    <TableCell>R$ 15.000,00</TableCell>
                    <TableCell>R$ 30.000,00</TableCell>
                    <TableCell>R$ 40.000,00</TableCell>
                    <TableCell>R$ 80.000,00</TableCell>
                    <TableCell>R$ 150.000,00</TableCell>
                    <TableCell align="right"><strong>R$ 135.000,00</strong></TableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <h3 style={{margin: 0}}>Valores Totais:</h3>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Box}>
            <Table aria-label="simple table" style={{minWidth: 650}}>
              <TableHead>
                <TableRow>
                  <TableCell style={{background: '#ccc'}}>Total em Moedas</TableCell>
                  <TableCell style={{background: '#ccc'}}>Total em Cédulas</TableCell>
                  <TableCell style={{background: '#ccc'}}>Valor Solicitado</TableCell>
                  <TableCell style={{background: '#ccc'}}>Valor da Entrega</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow key={1}>
                    <TableCell>R$ 6.000,00</TableCell>
                    <TableCell>R$ 15.000,00</TableCell>
                    <TableCell>R$ 30.000,00</TableCell>
                    <TableCell>R$ 2.000,00</TableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default SellingEdit;
