import React, { useState, useEffect } from "react";

import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { Chip, SvgIconTypeMap } from "@material-ui/core";
import axios from "axios";
import { useRecoilState } from "recoil";
import { settings, locale, getHeaders } from "../../settings";
import ContentWrapper from "../../components/ContentWrapper";
import MainHeader from "../../components/MainHeader";
import MainTable from "../../components/MainTable";
import TitleHeader from "../../components/TitleHeader";
import DividerWrapper from "../../components/DividerWrapper";
import ViewButton from "../../components/Buttons/ViewButton";
import AddButton from "../../components/Buttons/AddButton";
import green from '@material-ui/core/colors/green';

import StoresEdit from "./StoresEdit";

import {
  confirmationDialogState,
  dialogState,
  loadingOverlayState,
  snackBarState,
  handleErrorState,
} from "../../GlobalAtoms";

const Selling: React.FC<{
  icon: any;
}> = () => {
  // States
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const [editDialogStatus, setEditDialogStatus] = useState(false);
  const [editDialogData, setEditDialogData] = useState({});
  const [editKey, setEditKey] = useState<number | null>(null);

  // Recoil
  const [dialog, setDialogState] = useRecoilState(dialogState);
  const [confirmationDialog, setConfirmationDialogState] = useRecoilState(
    confirmationDialogState
  );
  // eslint-disable-next-line no-unused-vars
  const [handleError, setHandleError] = useRecoilState(handleErrorState);
  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] =
    useRecoilState(loadingOverlayState);
  // eslint-disable-next-line no-unused-vars
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);

  useEffect(() => {
    const url = `${settings().apiUrl}/api/sellings`;
    const headers = getHeaders();

    setData([    
      {
        id: '111111',
        view: '111111',
        name: 'Loja 1',
        initials: 'MCD-SP',
        legal_name: "Mc Donald's Morumbi",
        cnpj: "00.888/0001-22",
        user: 'Felipe Rangel',
        address: 'Rua Exemplo de Exemplo',
        number: '145',
        complement: 'bloco 2 sala 107',
        neighborhood: 'Barra da Tijuca',
        city: 'Rio de Janeiro',
        uf: 'RJ',
        zipcode: '22773-045',
      },
      {
        id: '2222222',
        view: '2222222',
        name: 'Loja 1',
        initials: 'MCD-SP',
        legal_name: "Mc Donald's Morumbi",
        cnpj: "00.888/0001-22",
        user: 'Felipe Rangel',
        address: 'Rua Exemplo de Exemplo',
        number: '145',
        complement: 'bloco 2 sala 107',
        neighborhood: 'Barra da Tijuca',
        city: 'Rio de Janeiro',
        uf: 'RJ',
        zipcode: '22773-045',
      },
      {
        id: '333333',
        view: '333333',
        name: 'Loja 1',
        initials: 'MCD-SP',
        legal_name: "Mc Donald's Morumbi",
        cnpj: "00.888/0001-22",
        user: 'Felipe Rangel',
        address: 'Rua Exemplo de Exemplo',
        number: '145',
        complement: 'bloco 2 sala 107',
        neighborhood: 'Barra da Tijuca',
        city: 'Rio de Janeiro',
        uf: 'RJ',
        zipcode: '22773-045',
      },
      {
        id: '444444444',
        view: '444444444',
        name: 'Loja 1',
        initials: 'MCD-SP',
        legal_name: "Mc Donald's Morumbi",
        cnpj: "00.888/0001-22",
        user: 'Felipe Rangel',
        address: 'Rua Exemplo de Exemplo',
        number: '145',
        complement: 'bloco 2 sala 107',
        neighborhood: 'Barra da Tijuca',
        city: 'Rio de Janeiro',
        uf: 'RJ',
        zipcode: '22773-045',
      },
    ]);
    setLoading(false);

    /*axios
      .get(url, { headers })
      .then((response) => {
        setData([...response.data.data]);
        setLoading(false);
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );*/
  }, []);


  const openEditDialog = (rowData: {}, indexKey: number) => {
    console.log('hey');
    setDialogState({
      ...dialog,
      open: true,
      title: "Visualização de Pedido",
      content: editDialog,
      maxWidth: "xl",
      transition: "regular",
    })
  };

  const deleteItem = async (id: number) => {
    setLoadingOverlayState(true);
    let url = `${settings().apiUrl}/api/sellings/${id}`;
    let headers = getHeaders();

    await axios
      .delete(url, { headers })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );

    url = `${settings().apiUrl}/api/sellings`;
    headers = getHeaders();

    axios
      .get(url, { headers })
      .then((response) => {
        setData([...response.data.data]);
        setLoadingOverlayState(false);
        setSnackBar({
          open: true,
          type: "success",
          message: locale.products.form.edit.success,
        });
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );
  };

  const editDialog = <StoresEdit />;

  const tableIndexes = locale.sellings.index.table;
  const columns = [
    {
      label: "ID",
      name: "id",
    },
    {
      label: "Nome",
      name: "name",
    },
    {
      label: "Sigla",
      name: "initials",
    },
    {
      label: "Razão Social",
      name: "legal_name",
    },
    {
      label: "CNPJ",
      name: "cnpj",
    },
    {
      label: "Responsável",
      name: "user",
    },
    {
      label: "Endereço",
      name: "address",
    },
    {
      label: "Número",
      name: "number",
    },
    {
      label: "Complemento",
      name: "complement",
    },
    {
      label: "Bairro",
      name: "neighborhood",
    },
    {
      label: "Cidade",
      name: "city",
    },
    {
      label: "UF",
      name: "uf",
    },
    {
      label: "CEP",
      name: "zipcode",
    },
  ];

  return (
    <>
      <ContentWrapper loading={loading}>
        <MainHeader
          title={<TitleHeader>{"Lojas"}</TitleHeader>}
        />
        <DividerWrapper />
        <MainTable data={data} columns={columns} />
      </ContentWrapper>
    </>
  );
};

export default Selling;
