import React, { useState, useEffect } from "react";

import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { Checkbox, Chip, Divider, FormControlLabel, FormGroup, Grid, SvgIconTypeMap, TextField } from "@material-ui/core";
import axios from "axios";
import { useRecoilState } from "recoil";
import { settings, locale, getHeaders } from "../../settings";
import ContentWrapper from "../../components/ContentWrapper";
import MainHeader from "../../components/MainHeader";
import MainTable from "../../components/MainTable";
import TitleHeader from "../../components/TitleHeader";
import DividerWrapper from "../../components/DividerWrapper";
import ViewButton from "../../components/Buttons/ViewButton";
import AddButton from "../../components/Buttons/AddButton";
import green from '@material-ui/core/colors/green';


import {
  confirmationDialogState,
  dialogState,
  loadingOverlayState,
  snackBarState,
  handleErrorState,
} from "../../GlobalAtoms";

const Buying: React.FC<{
  icon: any;
}> = () => {
  // States
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const [editDialogStatus, setEditDialogStatus] = useState(false);
  const [editDialogData, setEditDialogData] = useState({});
  const [editKey, setEditKey] = useState<number | null>(null);

  // Recoil
  const [dialog, setDialogState] = useRecoilState(dialogState);
  const [confirmationDialog, setConfirmationDialogState] = useRecoilState(
    confirmationDialogState
  );
  // eslint-disable-next-line no-unused-vars
  const [handleError, setHandleError] = useRecoilState(handleErrorState);
  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] =
    useRecoilState(loadingOverlayState);
  // eslint-disable-next-line no-unused-vars
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);

  useEffect(() => {
    const url = `${settings().apiUrl}/api/buyings`;
    const headers = getHeaders();

    setData([    
      {
        id: '111111',
        view: '111111',
        date: '23/01/2022 15:48',
        desired_date: '28/01/2022',
        user: 'Felipe Rangel',
        products: 'R$ 345,62',
        delivery_fee: 'R$ 38,96',
        processing_fee: 'R$ 145,62',
        total_fee: 'R$ 545,62',
        observations: 'Observações de exemplo 1',
        status: 'open',
        delivery: '',
      },
      {
        id: '2222222',
        view: '2222222',
        date: '23/01/2022 15:48',
        desired_date: '28/01/2022',
        user: 'Felipe Rangel',
        products: 'R$ 345,62',
        delivery_fee: 'R$ 38,96',
        processing_fee: 'R$ 145,62',
        total_fee: 'R$ 545,62',
        observations: 'Observações de exemplo 2',
        status: 'cancelled',
        delivery: '',
      },
      {
        id: '333333',
        view: '333333',
        date: '23/01/2022 15:48',
        desired_date: '28/01/2022',
        user: 'Helaine Ferreira',
        products: 'R$ 345,62',
        delivery_fee: 'R$ 38,96',
        processing_fee: 'R$ 145,62',
        total_fee: 'R$ 545,62',
        observations: 'Observações de exemplo 3',
        status: 'finalized',
        delivery: '',
      },
      {
        id: '444444444',
        view: '444444444',
        date: '23/01/2022 15:48',
        desired_date: '28/01/2022',
        user: 'Felipe Rangel',
        products: 'R$ 345,62',
        delivery_fee: 'R$ 38,96',
        processing_fee: 'R$ 145,62',
        total_fee: 'R$ 545,62',
        observations: 'Observações de exemplo 4',
        status: 'finalized',
        delivery: '27/01/2022 08:35',
      },
    ]);
    setLoading(false);

    /*axios
      .get(url, { headers })
      .then((response) => {
        setData([...response.data.data]);
        setLoading(false);
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );*/
  }, []);


  const openEditDialog = (rowData: {}, indexKey: number) => {
    console.log('hey');
    setDialogState({
      ...dialog,
      open: true,
      title: "Visualização de Pedido",
      content: editDialog,
      maxWidth: "xl",
      transition: "regular",
    })
  };

  const addDialog = <></>;

  const deleteItem = async (id: number) => {
    setLoadingOverlayState(true);
    let url = `${settings().apiUrl}/api/buyings/${id}`;
    let headers = getHeaders();

    await axios
      .delete(url, { headers })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );

    url = `${settings().apiUrl}/api/buyings`;
    headers = getHeaders();

    axios
      .get(url, { headers })
      .then((response) => {
        setData([...response.data.data]);
        setLoadingOverlayState(false);
        setSnackBar({
          open: true,
          type: "success",
          message: locale.products.form.edit.success,
        });
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );
  };

  const editDialog = <></>;

  const tableIndexes = locale.buyings.index.table;
  const columns = [
    {
      label: "Numero do pedido",
      name: "id",
    },
    {
      label: "Data do Pedido",
      name: "date",
    },
    {
      label: "Data Pretendida",
      name: "desired_date",
    },
    {
      label: "Usuário",
      name: "user",
    },
    {
      label: "Valor Solicitado",
      name: "products",
    },
    {
      label: "Valor de Entrega",
      name: "delivery_fee",
    },
    {
      label: "Valor de Processamento de Moeda",
      name: "processing_fee",
    },
    {
      label: "Valor Total",
      name: "total_fee",
    },
    {
      label: "Observações",
      name: "observations",
    },
    {
      label: "Status",
      name: "status",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: any,
          tableMeta: { rowIndex: number },
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <>
            {value === 'open' && <Chip label="Aberto" style={{background: "#43a047", color: "#fff"}} />}
            {value === 'cancelled' && <Chip label="Cancelado" style={{background: "#e53935", color: "#fff"}} />}
            {value === 'finalized' && <Chip label="Finalizado" style={{background: "#01579b", color: "#fff"}} />}
            {value === 'delivered' && <Chip label="Entregue" style={{background: "#006064", color: "#fff"}} />}
          </>
        ),
      },
    },
    {
      label: "Entrega",
      name: "delivery",
    },
    {
      label: " ",
      name: "view",
      options: {
        // eslint-disable-next-line no-unused-vars
        customBodyRender: (
          value: number,
          tableMeta: { rowIndex: number },
          // eslint-disable-next-line no-unused-vars
          updateValue: any
        ) => (
          <>
            <ViewButton
              onClick={() =>
                openEditDialog(data[tableMeta.rowIndex], tableMeta.rowIndex)
              }
            />
          </>
        ),
      },
    },
  ];

  return (
    <>
      <ContentWrapper loading={loading}>
        <MainHeader
          title={<TitleHeader>{"Empresa"}</TitleHeader>}
        />
        <DividerWrapper />
        <div style={{ padding: 20 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField id="outlined-basic1" label="Nome" fullWidth variant="outlined" InputProps={{readOnly: true}} value="Mc Donald's" />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField id="outlined-basic1" label="Sigla" fullWidth variant="outlined" InputProps={{readOnly: true}} value="MCD" />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField id="outlined-basic3" label="Razão Sozial" fullWidth variant="outlined" InputProps={{readOnly: true}} value="Mc Donlad's Alimentos" />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField id="outlined-basic2" label="CNPJ" fullWidth variant="outlined" InputProps={{readOnly: true}} value="44.555/0001-22" />
            </Grid>
            <Grid item xs={12} sm={6} lg={3}>
              <TextField id="outlined-basic2" label="Responsável" fullWidth variant="outlined" InputProps={{readOnly: true}} value="Felipe Rangel" />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <h3 style={{margin: 0}}>Endereço:</h3>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField id="outlined-basic1" label="Endereço" fullWidth variant="outlined" InputProps={{readOnly: true}} value="Endereço Exemplo" />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField id="outlined-basic2" label="N.:" fullWidth variant="outlined" InputProps={{readOnly: true}} value="Valor Exemplo" />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField id="outlined-basic1" label="Complemento" fullWidth variant="outlined" InputProps={{readOnly: true}} value="Valor Exemplo" />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField id="outlined-basic2" label="Cidade" fullWidth variant="outlined" InputProps={{readOnly: true}} value="Valor Exemplo" />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField id="outlined-basic2" label="CEP" fullWidth variant="outlined" InputProps={{readOnly: true}} value="Valor Exemplo" />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField id="outlined-basic2" label="UF" fullWidth variant="outlined" InputProps={{readOnly: true}} value="Valor Exemplo" />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <h3 style={{margin: 0}}>Serviços Disponíveis:</h3>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormGroup row>
                    <FormControlLabel
                      control={<Checkbox checked={true} onChange={() => {return false;}} name="checkedA" color="primary" />}
                      label="Aquisição de Troco"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField id="outlined-basic1" label="Valor do Frete" fullWidth variant="outlined" value="Endereço Exemplo" />
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField id="outlined-basic2" label="Custo do Milheiro" fullWidth variant="outlined" value="Valor Exemplo" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormGroup row>
                    <FormControlLabel
                      control={<Checkbox checked={true} onChange={() => {return false;}} name="checkedA" color="primary" />}
                      label="Recolhimento de Cédulas"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                  <TextField id="outlined-basic1" label="Valor do Frete" fullWidth variant="outlined" value="Endereço Exemplo" />
                </Grid>
              </Grid>
            </Grid>            
          </Grid>
        </div>
      </ContentWrapper>
    </>
  );
};

export default Buying;
