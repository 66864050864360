import React, { useState, useEffect } from "react";

import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { Chip, SvgIconTypeMap } from "@material-ui/core";
import axios from "axios";
import { useRecoilState } from "recoil";
import { settings, locale, getHeaders } from "../../settings";
import ContentWrapper from "../../components/ContentWrapper";
import MainHeader from "../../components/MainHeader";
import MainTable from "../../components/MainTable";
import TitleHeader from "../../components/TitleHeader";
import DividerWrapper from "../../components/DividerWrapper";
import ViewButton from "../../components/Buttons/ViewButton";
import AddButton from "../../components/Buttons/AddButton";
import green from '@material-ui/core/colors/green';


import {
  confirmationDialogState,
  dialogState,
  loadingOverlayState,
  snackBarState,
  handleErrorState,
} from "../../GlobalAtoms";

const Logs: React.FC<{
  icon: any;
}> = () => {
  // States
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const [editDialogStatus, setEditDialogStatus] = useState(false);
  const [editDialogData, setEditDialogData] = useState({});
  const [editKey, setEditKey] = useState<number | null>(null);

  // Recoil
  const [dialog, setDialogState] = useRecoilState(dialogState);
  const [confirmationDialog, setConfirmationDialogState] = useRecoilState(
    confirmationDialogState
  );
  // eslint-disable-next-line no-unused-vars
  const [handleError, setHandleError] = useRecoilState(handleErrorState);
  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] =
    useRecoilState(loadingOverlayState);
  // eslint-disable-next-line no-unused-vars
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);

  useEffect(() => {
    const url = `${settings().apiUrl}/api/sellings`;
    const headers = getHeaders();

    setData([    
      {
        id: '111',
        name: 'Usuário 1',
        date: '16/02/2022 08:38',
        action: 'Log in',
        order_number: "",
      },
      {
        id: '222',
        name: 'Usuário 2',
        date: '17/02/2022 08:38',
        action: 'Abertura de Pedido - Aquisição de troco',
        order_number: "7uf894",
      },
      {
        id: '333',
        name: 'Usuário 3',
        date: '17/02/2022 08:38',
        action: 'Confirmação de Entrega - Aquisição de troco',
        order_number: "f8sn50",
      },
      {
        id: '444',
        name: 'Usuário 1',
        date: '16/02/2022 08:38',
        action: 'Cancelamento de Pedido - Aquisição de troco',
        order_number: "pd927ns",
      },
    ]);
    setLoading(false);

    /*axios
      .get(url, { headers })
      .then((response) => {
        setData([...response.data.data]);
        setLoading(false);
      })
      .catch((error) =>
        setHandleError({ error: true, response: error.response })
      );*/
  }, []);


  const tableIndexes = locale.sellings.index.table;
  const columns = [
    {
      label: "ID",
      name: "id",
    },
    {
      label: "Usuário",
      name: "name",
    },
    {
      label: "Data",
      name: "date",
    },
    {
      label: "Ação",
      name: "action",
    },
    {
      label: "N. Pedido",
      name: "order_number",
    },
  ];

  return (
    <>
      <ContentWrapper loading={loading}>
        <MainHeader
          title={<TitleHeader>{"Log de Atividades"}</TitleHeader>}
        />
        <DividerWrapper />
        <MainTable data={data} columns={columns} />
      </ContentWrapper>
    </>
  );
};

export default Logs;
