import React, { useState } from "react";

import { Formik, Form, Field } from "formik";

import { useRecoilState } from "recoil";

import { Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, LinearProgress, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";

// @material-ui components
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import InputAdornment from '@material-ui/core/InputAdornment';
import axios from "axios";
import {
  dialogState,
  handleErrorState,
  loadingOverlayState,
  snackBarState,
} from "../../GlobalAtoms";
import SaveButton from "../../components/Buttons/SaveButton";
import HandleForm from "../../helpers/HandleForm";
import FormBuilder from "../../helpers/FormBuilder";

import { settings, locale, getHeaders } from "../../settings";
import validate from "./validation/buying";
import { ArrowBackIos, ShoppingCart } from "@material-ui/icons";
import DeleteButton from "../../components/Buttons/DeleteButton";

const BuyingAdd: React.FC<{
  setData: React.Dispatch<any>;
  data: {}[];
}> = () => {
  // eslint-disable-next-line no-unused-vars
  const [loadingOverlay, setLoadingOverlayState] =
    useRecoilState(loadingOverlayState);
  // eslint-disable-next-line no-unused-vars
  const [handleError, setHandleError] = useRecoilState(handleErrorState);

  // eslint-disable-next-line no-unused-vars
  const [checkout, setCheckout] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [snackBar, setSnackBar] = useRecoilState(snackBarState);
  const [dialog, setDialogState] = useRecoilState(dialogState);

  const toggleAgreed = () => {
    setAgreed(!agreed);
  }

  const { initialValues, formGrid, validationRules } = HandleForm(
    [
      {
        label: "name",
        type: "TextField",
        value: "teste",
        validationRules: "string|required",
        size: {
          xs: 12,
          sm: 6,
          lg: 4,
        },
      },
      {
        label: "switch",
        type: "Switch",
        value: false,
        validationRules: "boolean|required",
        size: {
          xs: 12,
          sm: 6,
          lg: 4,
        },
      },
      {
        label: "address",
        type: "TextField",
        value: "testeaaaaa",
        validationRules: "string|required",
        size: {
          xs: 12,
          sm: 12,
          lg: 12,
        },
      },
    ],
    locale.buyings.form
  );

  const terms = <>
    Li e Aceito os <a href="https://trello.com/1/cards/6206b79f92e77770a4fae940/attachments/621558d5b6049b636f3b755d/download/logo_zinner-removebg-preview.png" target="blank">Termos de Serviço</a>
  </>;

  return (
    <>
      {!checkout ?
      <Formik
        initialValues={initialValues}
        validationSchema={validate(validationRules)}
        onSubmit={(values, { setSubmitting }) => {
          console.log(values);
          // setLoadingOverlayState(true);
          // setDialogState({
          //   ...dialog,
          //   open: false,
          // });
          // const url = `${settings().apiUrl}/api/buyings`;
          // const headers = getHeaders();
          // const body = JSON.stringify({
          //   name: values.name,
          //   address: "fsddsdsd",
          //   checkin: 1,
          //   zipcode: values.zipcode,
          //   phone: values.phone,
          //   region: values.region,
          //   about: values.about,
          //   observations: values.observations,
          //   cost_rate: 1,
          //   company_id: 1,
          //   rate: 1,
          // });

          // axios
          //   .post(url, body, { headers })
          //   .then((response: { data: { data: {} } }) => {
          //     setLoadingOverlayState(false);
          //     const { data } = props;
          //     data.push(response.data.data);
          //     props.setData([...data]);
          //     setSnackBar({
          //       open: true,
          //       type: "success",
          //       message: locale.products.form.add.success,
          //     });
          //     setSubmitting(false);
          //   })
          //   .catch((error) =>
          //     setHandleError({ error: true, response: error.response })
          //   );
        }}
      >
        {({ values, handleChange, submitForm, isSubmitting }) => (
        <Form>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField id="outlined-basic1" label="Empresa" fullWidth disabled value="Empresa Exemplo" variant="filled" />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField id="outlined-basic3" label="Serviço Solicitado" fullWidth disabled value="Recolhimento de Cédulas" variant="filled" />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">Sigla</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={10}
                  onChange={handleChange}
                  label="Sigla"
                >
                  <MenuItem value={10}>Loja 1</MenuItem>
                  <MenuItem value={20}>Loja 2</MenuItem>
                  <MenuItem value={30}>Loja 3</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                label="Data de Preferência"
                id="desired_date"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={8}>
              <TextField
                label="Observações"
                id="observations"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <h3 style={{margin: 0}}>Informe quantos milheiros deseja para cada moeda:</h3>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6} sm={4} lg={2}>
              <TextField
                label=""
                id="coins-05"
                InputProps={{
                  endAdornment: <InputAdornment position="end">R$ 0,05</InputAdornment>,
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={4} lg={2}>
              <TextField
                label=""
                id="coins-10"
                InputProps={{
                  endAdornment: <InputAdornment position="end">R$ 0,10</InputAdornment>,
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={4} lg={2}>
              <TextField
                label=""
                id="coins-25"
                InputProps={{
                  endAdornment: <InputAdornment position="end">R$ 0,25</InputAdornment>,
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={4} lg={2}>
              <TextField
                label=""
                id="coins-50"
                InputProps={{
                  endAdornment: <InputAdornment position="end">R$ 0,50</InputAdornment>,
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={4} lg={2}>
              <TextField
                label=""
                id="coins-100"
                InputProps={{
                  endAdornment: <InputAdornment position="end">R$ 1,00</InputAdornment>,
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <h3 style={{margin: 0}}>Informe quantos milheiros deseja para cada cédula:</h3>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={6} sm={4} lg={2}>
              <TextField
                label=""
                id="ballot-2"
                InputProps={{
                  endAdornment: <InputAdornment position="end">R$ 2,00</InputAdornment>,
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={4} lg={2}>
              <TextField
                label=""
                id="ballot-5"
                InputProps={{
                  endAdornment: <InputAdornment position="end">R$ 5,00</InputAdornment>,
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={4} lg={2}>
              <TextField
                label=""
                id="ballot-10"
                InputProps={{
                  endAdornment: <InputAdornment position="end">R$ 10,00</InputAdornment>,
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={6} sm={4} lg={2}>
              <TextField
                label=""
                id="ballot-20"
                InputProps={{
                  endAdornment: <InputAdornment position="end">R$ 20,00</InputAdornment>,
                }}
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Box}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{background: '#ccc'}}>R$ 0,05</TableCell>
                      <TableCell style={{background: '#ccc'}}>R$ 0,10</TableCell>
                      <TableCell style={{background: '#ccc'}}>R$ 0,25</TableCell>
                      <TableCell style={{background: '#ccc'}}>R$ 0,50</TableCell>
                      <TableCell style={{background: '#ccc'}}>R$ 1,00</TableCell>
                      <TableCell style={{background: '#ccc'}} align="right"><strong>Total</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                      <TableRow key={1}>
                        <TableCell>R$ 150,00</TableCell>
                        <TableCell>R$ 250,00</TableCell>
                        <TableCell>R$ 750,00</TableCell>
                        <TableCell>R$ 1.500,00</TableCell>
                        <TableCell>R$ 3.500,00</TableCell>
                        <TableCell align="right"><strong>R$ 7.950,00</strong></TableCell>
                      </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12}>
              <TableContainer component={Box}>
                <Table aria-label="simple table" style={{minWidth: 650}}>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{background: '#ccc'}}>R$ 2,00</TableCell>
                      <TableCell style={{background: '#ccc'}}>R$ 5,00</TableCell>
                      <TableCell style={{background: '#ccc'}}>R$ 10,00</TableCell>
                      <TableCell style={{background: '#ccc'}}>R$ 20,00</TableCell>
                      <TableCell style={{background: '#ccc'}} align="right"><strong>Total</strong></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                      <TableRow key={1}>
                        <TableCell>R$ 6.000,00</TableCell>
                        <TableCell>R$ 15.000,00</TableCell>
                        <TableCell>R$ 30.000,00</TableCell>
                        <TableCell>R$ 40.000,00</TableCell>
                        <TableCell align="right"><strong>R$ 135.000,00</strong></TableCell>
                      </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {isSubmitting && <LinearProgress />}
            <Grid item xs={12} sm={12}>
              <Box>
                <SaveButton
                  type="submit"
                  disabled={isSubmitting}
                  onClick={() => setCheckout(true)}
                >{<><ShoppingCart style={{ fontSize: 18, marginRight: 10 }} /> Ir Para Confirmação</>}</SaveButton>
              </Box>
            </Grid>
          </Grid>
        </Form>
        )}
      </Formik>
      :
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h2 style={{margin: 0}}>Confirme seus dados para finalizar o pedido</h2>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <TextField id="outlined-basic1" label="N. Pedido" fullWidth InputProps={{readOnly: true}} value="54352" />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <TextField id="outlined-basic1" label="Empresa" fullWidth InputProps={{readOnly: true}} value="Empresa Exemplo" />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <TextField id="outlined-basic2" label="Sigla" fullWidth InputProps={{readOnly: true}} value="Loja Exemplo" />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <TextField id="outlined-basic3" label="Serviço Solicitado" fullWidth InputProps={{readOnly: true}} value="Aquisição de Troco" />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <TextField
            label="Data de Preferência"
            id="desired_date"
            value="27/02/2022"
            fullWidth
            InputProps={{readOnly: true}}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={9}>
          <TextField
            label="Observações"
            id="observations"
            value="Observações de teste"
            fullWidth
            InputProps={{readOnly: true}}
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <h3 style={{margin: 0}}>Dados da Entrega:</h3>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField id="outlined-basic1" label="Endereço" fullWidth InputProps={{readOnly: true}} value="Endereço Exemplo" />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField id="outlined-basic2" label="N.:" fullWidth InputProps={{readOnly: true}} value="Valor Exemplo" />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField id="outlined-basic1" label="Complemento" fullWidth InputProps={{readOnly: true}} value="Valor Exemplo" />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField id="outlined-basic2" label="Cidade" fullWidth InputProps={{readOnly: true}} value="Valor Exemplo" />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField id="outlined-basic2" label="CEP" fullWidth InputProps={{readOnly: true}} value="Valor Exemplo" />
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <TextField id="outlined-basic2" label="UF" fullWidth InputProps={{readOnly: true}} value="Valor Exemplo" />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <h3 style={{margin: 0}}>Milheiros por moeda:</h3>
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <TextField
            variant="outlined"
            label=""
            id="coins-05"
            InputProps={{
              endAdornment: <InputAdornment position="end">R$ 0,05</InputAdornment>,
              readOnly: true
            }}
            fullWidth
            value="2"
          />
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <TextField
            variant="outlined"
            label=""
            id="coins-10"
            InputProps={{
              endAdornment: <InputAdornment position="end">R$ 0,10</InputAdornment>,
              readOnly: true
            }}
            fullWidth
            value="9"
          />
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <TextField
            variant="outlined"
            label=""
            id="coins-25"
            InputProps={{
              endAdornment: <InputAdornment position="end">R$ 0,25</InputAdornment>,
              readOnly: true
            }}
            fullWidth
            value="7"
          />
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <TextField
            variant="outlined"
            label=""
            id="coins-50"
            InputProps={{
              endAdornment: <InputAdornment position="end">R$ 0,50</InputAdornment>,
              readOnly: true
            }}
            fullWidth
            value="3"
          />
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <TextField
            variant="outlined"
            label=""
            id="coins-100"
            InputProps={{
              endAdornment: <InputAdornment position="end">R$ 1,00</InputAdornment>,
              readOnly: true
            }}
            fullWidth
            value="1"
          />
        </Grid>
        <Grid item xs={12}>
          <h3 style={{margin: 0}}>Milheiros por cédula:</h3>
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <TextField
            variant="outlined"
            label=""
            id="ballot-2"
            InputProps={{
              endAdornment: <InputAdornment position="end">R$ 2,00</InputAdornment>,
              readOnly: true
            }}
            fullWidth
            value="4"
          />
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <TextField
            variant="outlined"
            label=""
            id="ballot-5"
            InputProps={{
              endAdornment: <InputAdornment position="end">R$ 5,00</InputAdornment>,
              readOnly: true
            }}
            fullWidth
            value="2"
          />
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <TextField
            variant="outlined"
            label=""
            id="ballot-10"
            InputProps={{
              endAdornment: <InputAdornment position="end">R$ 10,00</InputAdornment>,
              readOnly: true
            }}
            fullWidth
            value="3"
          />
        </Grid>
        <Grid item xs={6} sm={4} lg={2}>
          <TextField
            variant="outlined"
            label=""
            id="ballot-20"
            InputProps={{
              endAdornment: <InputAdornment position="end">R$ 20,00</InputAdornment>,
              readOnly: true
            }}
            fullWidth
            value="6"
          />
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Box}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{background: '#ccc'}}>R$ 0,05</TableCell>
                  <TableCell style={{background: '#ccc'}}>R$ 0,10</TableCell>
                  <TableCell style={{background: '#ccc'}}>R$ 0,25</TableCell>
                  <TableCell style={{background: '#ccc'}}>R$ 0,50</TableCell>
                  <TableCell style={{background: '#ccc'}}>R$ 1,00</TableCell>
                  <TableCell style={{background: '#ccc'}} align="right"><strong>Total</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow key={1}>
                    <TableCell>R$ 150,00</TableCell>
                    <TableCell>R$ 250,00</TableCell>
                    <TableCell>R$ 750,00</TableCell>
                    <TableCell>R$ 1.500,00</TableCell>
                    <TableCell>R$ 3.500,00</TableCell>
                    <TableCell align="right"><strong>R$ 7.950,00</strong></TableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Box}>
            <Table aria-label="simple table" style={{minWidth: 650}}>
              <TableHead>
                <TableRow>
                  <TableCell style={{background: '#ccc'}}>R$ 2,00</TableCell>
                  <TableCell style={{background: '#ccc'}}>R$ 5,00</TableCell>
                  <TableCell style={{background: '#ccc'}}>R$ 10,00</TableCell>
                  <TableCell style={{background: '#ccc'}}>R$ 20,00</TableCell>
                  <TableCell style={{background: '#ccc'}} align="right"><strong>Total</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow key={1}>
                    <TableCell>R$ 6.000,00</TableCell>
                    <TableCell>R$ 15.000,00</TableCell>
                    <TableCell>R$ 30.000,00</TableCell>
                    <TableCell>R$ 40.000,00</TableCell>
                    <TableCell align="right"><strong>R$ 235.000,00</strong></TableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <h3 style={{margin: 0}}>Valores Totais:</h3>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Box}>
            <Table aria-label="simple table" style={{minWidth: 650}}>
              <TableHead>
                <TableRow>
                  <TableCell style={{background: '#ccc'}}>Total em Moedas</TableCell>
                  <TableCell style={{background: '#ccc'}}>Total em Cédulas</TableCell>
                  <TableCell style={{background: '#ccc'}}>Valor Solicitado</TableCell>
                  <TableCell style={{background: '#ccc'}}>Valor da Entrega</TableCell>
                  <TableCell style={{background: '#ccc'}}>Valor do Processamento da Moeda</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow key={1}>
                    <TableCell>R$ 6.000,00</TableCell>
                    <TableCell>R$ 15.000,00</TableCell>
                    <TableCell>R$ 30.000,00</TableCell>
                    <TableCell>R$ 2.000,00</TableCell>
                    <TableCell>R$ 2.000,00</TableCell>
                  </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12}>
          <FormGroup row>
            <FormControlLabel
              control={<Checkbox checked={agreed} onChange={toggleAgreed} name="checkedA" color="primary" />}
              label={terms}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box>
            <SaveButton
              type="submit"
              disabled={!agreed}
              onClick={() => {return false;}}
            >{<><ShoppingCart style={{ fontSize: 18, marginRight: 10 }} /> Finalizar Pedido</>}</SaveButton>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box>
            <DeleteButton
              onClick={() => setCheckout(false)}
              style={{width: '100%'}}
            >{<><ArrowBackIos style={{ fontSize: 18, marginRight: 10 }} /> Editar Informações</>}</DeleteButton>
          </Box>
        </Grid>
      </Grid>
      }
    </>
  );
};
export default BuyingAdd;
